/* eslint-disable no-var */
/**
 * Main JS file for Horace behaviours
 */
;(function ($) {
  const $body = $('body')

  $(document).ready(() => {
    // Responsive video embeds
    $('.post-content').fitVids()

    // Scroll to top
    $('#top-button').on('click', (e) => {
      $('html, body').animate({
        scrollTop: 0,
      })
      e.preventDefault()
    })

    // Sidebar
    $('#sidebar-show, #sidebar-hide').on('click', function (e) {
      $body.toggleClass('sidebar--opened')
      $(this).blur()
      e.preventDefault()
    })
    $('#site-overlay').on('click', (e) => {
      $body.removeClass('sidebar--opened')
      e.preventDefault()
    })

    // Show comments
    const interval = setInterval(() => {
      const disqusHeight = $('#disqus_thread').height()
      if (disqusHeight > 100) {
        $('#comments-area').addClass('comments--loaded')
        clearInterval(interval)
      }
    }, 100)
    $('#comments-overlay, #comments-show').on('click', (e) => {
      $('#comments-area').removeClass('comments--loaded').addClass('comments--opened')
      e.preventDefault()
    })
  })
})(jQuery)
