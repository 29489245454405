/*
 * FitVids 1.1
 * Copyright 2013, Chris Coyier - http://css-tricks.com + Dave Rupert - http://daverupert.com
 * Credit to Thierry Koblentz - http://www.alistapart.com/articles/creating-intrinsic-ratios-for-video/
 * Released under the WTFPL license - http://sam.zoy.org/wtfpl/
 */

!(function (t) {
  t.fn.fitVids = function (e) {
    const i = { customSelector: null }
    if (!document.getElementById('fit-vids-style')) {
      const r = document.head || document.getElementsByTagName('head')[0]
      const d =
        '.fluid-width-video-wrapper{width:100%;position:relative;padding:0;}.fluid-width-video-wrapper iframe,.fluid-width-video-wrapper object,.fluid-width-video-wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}'
      const a = document.createElement('div')
      ;(a.innerHTML = `<p>x</p><style id="fit-vids-style">${d}</style>`),
        r.appendChild(a.childNodes[1])
    }
    return (
      e && t.extend(i, e),
      this.each(function () {
        const e = [
          "iframe[src*='player.vimeo.com']",
          "iframe[src*='youtube.com']",
          "iframe[src*='youtube-nocookie.com']",
          "iframe[src*='kickstarter.com'][src*='video.html']",
          'object',
          'embed',
        ]
        i.customSelector && e.push(i.customSelector)
        let r = t(this).find(e.join(','))
        ;(r = r.not('object object')),
          r.each(function () {
            const e = t(this)
            if (
              !(
                (this.tagName.toLowerCase() === 'embed' && e.parent('object').length) ||
                e.parent('.fluid-width-video-wrapper').length
              )
            ) {
              const i =
                this.tagName.toLowerCase() === 'object' ||
                (e.attr('height') && !isNaN(parseInt(e.attr('height'), 10)))
                  ? parseInt(e.attr('height'), 10)
                  : e.height()
              const r = isNaN(parseInt(e.attr('width'), 10))
                ? e.width()
                : parseInt(e.attr('width'), 10)
              const d = i / r
              if (!e.attr('id')) {
                const a = `fitvid${Math.floor(999999 * Math.random())}`
                e.attr('id', a)
              }
              e
                .wrap('<div class="fluid-width-video-wrapper"></div>')
                .parent('.fluid-width-video-wrapper')
                .css('padding-top', `${100 * d}%`),
                e.removeAttr('height').removeAttr('width')
            }
          })
      })
    )
  }
})(window.jQuery || window.Zepto)
